import React from 'react';
import {injectIntl} from "gatsby-plugin-intl"
import insuredTickets from '../../assets/images/insured-tickets.png';
import encryption from '../../assets/images/encryption.png';
import auditing from '../../assets/images/auditing.png';
import anything from '../../assets/images/anything.png';
import './security.scss';
import { Helmet } from "react-helmet"

const Security = ({ intl: { formatMessage } }) => {

  const translate = (str) => (formatMessage({id: str}))
  return (
    <div className={'security-page-wrapper'}>
      <Helmet>
        <title>Lotteroo | {translate('Security')}</title>
      </Helmet>
      <div className="top-block">
        <div className={'title'}>{translate('Security')}</div>
      </div>
      <div className={'page-content'}>
        <div className={'top-text'}>
          <h1 id="security-at-lotteroo">{translate('Security at Lotteroo')}</h1>
          <h3 id="your-trust-matters-to-us">{translate('Your trust matters to us')}</h3>
          <p>{translate('securityTopText')}</p>
        </div>
        <div className="block">
          <div className="img">
            <img src={insuredTickets} alt="" className={'img-fixed'}/>
          </div>
          <div className="text">
            <h2 id="insured-tickets">{translate('Insured Tickets')}</h2>
            <h3 id="every-play-insured">{translate('Every play insured')}</h3>
            <p>{translate('InsuredText')}</p>
          </div>
        </div>
        <div className="block">
          <div className="img">
            <img src={auditing} alt="" className={'img-fixed'}/>
          </div>
          <div className="text">
            <h2 id="independent-auditing">{translate('Independent Auditing')}</h2>
            <h3 id="our-company-turned-inside-out-regularly">{translate('Our company turned inside out, regularly')}</h3>
            <p>{translate('IndependentText')} </p>
          </div>
        </div>
        <div className="block">
          <div className="img">
            <img src={encryption} alt={translate('Encryption')} className={'img-fixed'}/>
          </div>
          <div className="text">
            <h2 id="encryption">{translate('Encryption')}</h2>
            <h3 id="secure-channels">{translate('Secure channels')}</h3>
            <p>{translate('EncryptionText')}</p>
          </div>
        </div>
        <div className="block">
          <div className="img">
            <img src={anything} alt="" className={'img-fixed'}/>
          </div>
          <div className="text">
            <h2 id="anything-else">{translate('Anything Else')} </h2>
            <h3 id="still-need-answers-">{translate('Still need answers?')}</h3>
            <p>{translate('AnythingElseText')}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(Security)
